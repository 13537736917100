import { FunctionComponent } from "react";
import Link from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";
import styles from "../../styles/components/Topline/ToplineDesktopTabs.module.scss";
import { usePlatform } from "@/helpers/platformContext";

const ToplineDesktopTabs: FunctionComponent<{ isWhite?: boolean }> = ({
  isWhite = false,
}) => {
  const { menu_sections } = usePlatform();
  const specialLink = menu_sections.find((el) => el.section_type === "special")
    ?.menu_items[0] || { title: "", url: "" };
  const router = useRouter();

  return (
    <div className={classNames(styles.tabs, { [styles.__white]: isWhite })}>
      <Link href="/">
        <a
          data-testid="header_link_main"
          className={classNames({
            [styles.__active]: router.pathname === "/",
          })}
        >
          Главное
        </a>
      </Link>
      <Link href="/news">
        <a
          data-testid="header_link_news"
          className={classNames(styles.__all, {
            [styles.__active]: router.pathname === "/news",
          })}
        >
          Все новости
        </a>
      </Link>
      {!!specialLink.title && (
        <Link href={specialLink.url}>
          <a
            data-testid="header_link_special"
            className={classNames(styles.__special, {
              [styles.__active]: router.asPath === specialLink.url,
            })}
            target={specialLink.url.startsWith("/") ? "_self" : "_blank"}
          >
            {specialLink.title}
          </a>
        </Link>
      )}
    </div>
  );
};

export default ToplineDesktopTabs;
